import React from 'react'
import styled from 'styled-components'

import Label from 'src/UIComponents/Label/Label'

import { lightestGray, gray, smallerText } from 'src/Styles/settings/Constants'

const Footer = () => (
  <Container>
    <Label color={gray} fontSize={smallerText}>
      Caso não se sinta satisfeito após atendimento, fale com a Ouvidoria: 0800 555 0045 | ouvidoria@zoop.com.br
    </Label>
  </Container>
)

const Container = styled.div`
  background-color: ${lightestGray};
  margin: 0 30px;
  display: flex;
  justify-content: center;
`

export default Footer
