import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import t from 'tcomb-form'

import Grid from '../Grid/Grid'
import Button from '../Button/ButtonDefault'
import { FloatingInput, FloatingLabel } from '../FloatingLabelInput/FloatingLabelInput'

import { middleMargin } from '../../Styles/settings/Constants'

const Form = t.form.Form

class FormZ extends Component {
  onSubmitHandler = (e) => {
    e.preventDefault()
    const { onSubmitForm } = this.props
    onSubmitForm && onSubmitForm()
  }

  onChangeHandler = (formValues) => {
    const { onChange } = this.props
    onChange && onChange(formValues)
  }

  render () {
    const options = { ...this.props.options, auto: 'none' }
    const { struct, values, labelButton, refs, disabledForm, buttonFullWidth, viewMode, disableSubmitButton, isRequesting, sizeButton } = this.props
    return (
      <Grid noPadding>
        <Grid.Row auto>
          <FormContainer data-tracking={`${this.props['data-tracking']} > Form`} onSubmit={this.onSubmitHandler} viewMode={viewMode} disabledForm={disabledForm}>
            <Grid.Row auto>
              <Form
                ref={refs}
                options={options}
                type={struct}
                onChange={this.onChangeHandler}
                value={values}
              />
            </Grid.Row>
            { this.props.children }
            {!disabledForm && labelButton && sizeButton && (
              <ButtonContainer sizeButton={sizeButton}>
                <ButtonForm data-tracking={`${this.props['data-tracking']} > BtnSubmit`} data-test='btn-submit-form' disabled={disableSubmitButton} isLoading={isRequesting} type='submit'>
                  {labelButton}
                </ButtonForm>
              </ButtonContainer>
            )}
            {!disabledForm && labelButton && !sizeButton && (
              <Grid.Row auto={buttonFullWidth}>
                <ButtonForm data-tracking={`${this.props['data-tracking']} > BtnSubmit`} data-test='btn-submit-form' disabled={disableSubmitButton} isLoading={isRequesting} type='submit'>
                  {labelButton}
                </ButtonForm>
              </Grid.Row>
            )}
          </FormContainer>
        </Grid.Row>
      </Grid>
    )
  }
}

FormZ.defaultProps = {
  ref: 'FormContainer',
  isRequesting: false
}

FormZ.propTypes = {
  children: PropTypes.any,
  options: PropTypes.object.isRequired,
  struct: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
  labelButton: PropTypes.string,
  refs: PropTypes.string,
  'data-tracking': PropTypes.string,
  disabledForm: PropTypes.bool,
  buttonFullWidth: PropTypes.bool,
  disableSubmitButton: PropTypes.bool,
  isRequesting: PropTypes.bool,
  viewMode: PropTypes.bool,
  sizeButton: PropTypes.string
  // cardHeader: PropTypes.string,
  // submitLabel: PropTypes.string,
  // cancelLabel: PropTypes.string,
  // deleteLabel: PropTypes.string,
  // errorMessage: PropTypes.string,
  // infoMessage: PropTypes.string,
  // onEditForm: PropTypes.func,
  // onCancelForm: PropTypes.func,
  // onDeleteForm: PropTypes.func,
  // footerShowLoading: PropTypes.bool,
  // contentShowLoading: PropTypes.bool,
  // customClassName: PropTypes.string,
  // shouldRenderFooter: PropTypes.bool
}

const ButtonContainer = styled.div`
  width: ${({ sizeButton }) => sizeButton || '100%'};
`

const ButtonForm = styled(Button)`
  margin-bottom: ${middleMargin};
`

const FormContainer = styled.form`
  ${({ viewMode, disabledForm }) => (viewMode || disabledForm) && css`
    & ${FloatingInput} {
      opacity: 1;
    }

    & ${FloatingLabel} {
      opacity: 1;
      transform: translate3d(0, -70%, 0) scale(0.80);
    }
  `}
`

export default FormZ
