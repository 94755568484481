import React from 'react'
import ContentLoader from 'react-content-loader'

const FutureTransferCardsLoader = (props) => (
  <ContentLoader
    speed={4}
    width={90}
    height={20}
    viewBox='0 0 90 20'
    backgroundColor='#f4f4f4'
    foregroundColor='#dcdcdc'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='90' height='20' />
  </ContentLoader>
)

export default FutureTransferCardsLoader
