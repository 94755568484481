import React from 'react'
import Proptypes from 'prop-types'
import styled, { css } from 'styled-components'

import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import FutureTransferCardsLoader from './FutureTransferCardsLoader'
import { toMoney } from 'src/Utils/Utils'
import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import ReloadIcon from 'src/Assets/icons/actions/reload.svg'

import {
  lighterGray,
  darkGray,
  darkerGray,
  bigText,
  bigMargin,
  normalText,
  red
} from 'src/Styles/settings/Constants'
import moment from 'moment'
import ErrorStateCard from '../../Components/ErrorStateCard/ErrorStateCard'

const FutureTransferHeaderInfo = (
  {
    nextFutureTransfer,
    nextFutureTransferLoading,
    nextFutureTransferError,
    totalAmountFutureTransfer,
    totalAmountFutureTransferLoading,
    totalAmountFutureTransferError,
    action
  }) => {
  return (
    <Container>
      <InfoCard>
        <TopInfoCard />
        <InfoCardContainer className='kpis'>
          {
            (
              <>

                <CustomCard>
                  {(!nextFutureTransfer && !nextFutureTransferLoading && !nextFutureTransferError) && <EmptyState
                    iconSrc='/assets/icons/alerts/alert.svg'
                    description='Sem pagamentos previstos.'
                    iconColor={darkerGray}
                    iconSize='small'
                    data-test='empty-state-next-future-transfer'
                  />}
                  {(!nextFutureTransfer && !nextFutureTransferLoading && nextFutureTransferError) && <ErrorStateCard
                    iconSrc={ReloadIcon}
                    iconColor={red}
                    data-test='error-state-next-future-transfer'
                    action={action}
                  />}
                  {(nextFutureTransfer || nextFutureTransferLoading) &&
                    <>
                      <Label
                        bold
                        color={darkGray}
                        fontSize={normalText}
                        uppercase
                        data-test='label-next-future-transfer'
                      >
                        Próximo pagamento previsto para {nextFutureTransferLoading ? <FutureTransferCardsLoader /> : moment(nextFutureTransfer.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </Label>
                      <Label
                        bold
                        fontSize={bigText}
                        data-test='next-future-transfer-value'
                      >
                        {nextFutureTransferLoading ? <FutureTransferCardsLoader /> : toMoney(nextFutureTransfer.amount / 100)}
                      </Label>
                      <LabelSpaced>
                        <Label>*O valor exibido aqui não inclui pagamentos do dia de hoje.</Label>
                      </LabelSpaced>
                    </>
                  }

                </CustomCard>
                <CustomCard>
                  {(!totalAmountFutureTransfer && !totalAmountFutureTransferLoading && !totalAmountFutureTransferError) && <EmptyState
                    iconSrc='/assets/icons/alerts/alert.svg'
                    description='Sem pagamentos previstos.'
                    iconColor={darkerGray}
                    iconSize='small'
                    data-test='empty-state-total-future-transfer'
                  />}
                  {(!totalAmountFutureTransfer && !totalAmountFutureTransferLoading && totalAmountFutureTransferError) && <ErrorStateCard
                    iconSrc={ReloadIcon}
                    iconColor={red}
                    data-test='error-state-total-future-transfer'
                    action={action}
                  />}
                  {(totalAmountFutureTransfer || totalAmountFutureTransferLoading) &&
                    <>
                      <Label
                        bold
                        color={darkGray}
                        fontSize={normalText}
                        uppercase
                        data-test='label-total-future-transfer'
                      >
                        Total de lançamentos futuros previstos
                      </Label>
                      <Label
                        bold
                        fontSize={bigText}
                        data-test='total-future-transfer-value'
                      >
                        {totalAmountFutureTransferLoading || !totalAmountFutureTransfer ? <FutureTransferCardsLoader /> : toMoney(totalAmountFutureTransfer)}
                      </Label>
                    </>
                  }
                </CustomCard>
              </>
            )
          }
        </InfoCardContainer>
      </InfoCard>
    </Container>
  )
}

FutureTransferHeaderInfo.propTypes = {
  nextFutureTransfer: Proptypes.object,
  nextFutureTransferLoading: Proptypes.boolean,
  nextFutureTransferError: Proptypes.object,
  totalAmountFutureTransfer: Proptypes.number,
  totalAmountFutureTransferLoading: Proptypes.boolean,
  totalAmountFutureTransferError: Proptypes.object,
  action: Proptypes.func
}

const Container = styled.div`
  display: block;
  margin-bottom: ${bigMargin};
`

const InfoCard = styled.div`
  display: block;
`

const TopInfoCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${lighterGray};
  border-radius: 10px;
  padding: 10px 20px;
  min-height: 110px;
  margin-bottom: 90px;
`

const InfoCardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: -170px;
`

const CustomCard = styled(Card)`
  height: 100px;
  justify-content: center;
  text-align: center;
  margin: 3px 7px 0 7px;

  &:nth-child(2) {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  ${({ disabled }) => disabled && css`
    rect {
      fill: ${lighterGray};
    }
  `}

  ${({ disabled }) => !disabled && css`
    rect {
      fill: ${darkGray};
      transition: fill .3s ease-in-out;
    }

    &:hover {
      rect {
        fill: ${props => props.theme.color};
      }
    }
  `}

`
const LabelSpaced = styled(Label)`
    margin-top: 1rem;
`

export default FutureTransferHeaderInfo
