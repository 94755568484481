import styled from 'styled-components'

import { spacePadding } from 'src/Styles/settings/Constants'

const FormField = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${spacePadding};
`

export default FormField
